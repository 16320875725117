import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import Home from '../pages/Home';
import Sobre from '../pages/Sobre'
import Contatos from '../pages/Contatos';
import Obras from '../pages/Obras';
import Clientes from '../pages/Clientes';
import PlataformasElevatorias from '../pages/PlataformasElevatorias';
import SondagemCampanula from '../pages/SondagemCampanula';
import SondagemPlataforma from '../pages/SondagemPlataforma';
import SondagemFlutuante from '../pages/SondagemFlutuante';
import SondagemVibracore from '../pages/SondagemVibracore';
import SondagemGeotecnica from '../pages/SondagemGeotecnica';
import FundacoesEspeciais from '../pages/FundacoesEspeciais';
import ContencaoDeEncostas from '../pages/ContencaoDeEncostas';
import ObrasCivis from '../pages/ObrasCivis';
import RecuperacaoEstrutural from '../pages/RecuperacaoEstrutural';




function Routes(){
    return (
        <BrowserRouter>
        <Route  path="/" exact component={Home}/>
        <Route  path="/sobre" component={Sobre}/>
        <Route  path="/obras" component={Obras}/>
        <Route  path="/clientes" component={Clientes}/>
        <Route  path="/contatos" component={Contatos}/>
        <Route  path="/servicos/aluguel-de-plataformas-autoelevatorias" component={PlataformasElevatorias}/>
        <Route  path="/servicos/contencao-de-encostas" component={ContencaoDeEncostas}/>
        <Route  path="/servicos/fundacoes-especiais-estaca-raiz" component={FundacoesEspeciais}/>
        <Route  path="/servicos/obras-civis" component={ObrasCivis}/>
        <Route  path="/servicos/recuperacao-estrutural" component={RecuperacaoEstrutural}/>
        <Route  path="/servicos/sondagens-subaquaticas-com-campanula-de-mergulho" component={SondagemCampanula}/>
        <Route  path="/servicos/sondagens-subaquaticas-com-flutuantes" component={SondagemFlutuante}/>
        <Route  path="/servicos/sondagens-subaquaticas-com-plataformas" component={SondagemPlataforma}/>
        <Route  path="/servicos/sondagens-subaquaticas-com-vibracore" component={SondagemVibracore}/>
        <Route  path="/servicos/sondagens-geotecnicas" component={SondagemGeotecnica}/>
        </BrowserRouter>
    )
}

export default Routes;
