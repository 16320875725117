import React from 'react'
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import CarouselContainer from "../../components/CarouselContainer";
import Footer from "../../components/Footer";
import CardsHome from '../../components/CardsHome';

function Home() {
  return (
    <>
      <Helmet>
        <title>Geodrill</title>
      </Helmet>
      <Header />
      <CarouselContainer />
      <CardsHome />
      <Footer />
    </>
  );
}

export default Home;
