/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import "./styles.css";

import Carousel1 from "../../assets/Carousel1.jpg";
import Carousel2 from "../../assets/Carousel2.jpg";
import Carousel3 from "../../assets/Carousel3.jpg";
import Carousel4 from "../../assets/Carousel4.jpg";
import Carousel5 from "../../assets/Carousel5.jpg";

import { Carousel, Col, Container, Row, Image } from "react-bootstrap";

function CarouselContainer() {
    return (
        <Container className="home">
            <Row>
                <Col>
                    <Carousel fade variant="dark">
                        <Carousel.Item interval={3000}>
                            <Image
                                className="d-block w-100"
                                src={Carousel1}
                                alt=""
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={3000}>
                            <Image
                                className="d-block w-100"
                                src={Carousel2}
                                alt=""
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={3000}>
                            <Image
                                className="d-block w-100"
                                src={Carousel3}
                                alt=""
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={3000}>
                            <Image
                                className="d-block w-100"
                                src={Carousel4}
                                alt=""
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={3000}>
                            <Image
                                className="d-block w-100"
                                src={Carousel5}
                                alt=""
                            />
                        </Carousel.Item>
                    </Carousel>
                </Col>
            </Row>
        </Container>
    );
}

export default CarouselContainer;
