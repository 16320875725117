import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import "./styles.css";

function MainObras() {
    return (
        <main className="obras">
            <Container>
                <Row>
                    <Col>
                        <h1>Obras</h1>
                        <hr />
                        <h3>Trabalhos recentes:</h3>
                            <h4>
                                Sondagem e investigações geotécnicas em água:
                            </h4>
                        <li>BELOV ENGENHARIA – Porto de Paranaguá – PR;</li>
                        <li>BELOV ENGENHARIA - Porto de São Luiz – MA;</li>
                        <li>
                            TEPOR – TERMINAL PORTUÁRIO DE MACAÉ – Macaé – RJ;
                        </li>
                        <li>
                            GOLAR POWER – TGS – TERMINAL DE GÁS – Joinville –
                            SC;
                        </li>
                        <li>
                            CELSE / GOLAR POWER – TERMINAL DE GÁS – Barra dos
                            Coqueiros – SE;
                        </li>
                        <li>PLANAVE – PORTO DE ARATU – BA;</li>
                        <li>
                            CONSORCIO BOSKALIS – FABIO BRUNO – SLI – Porto de
                            Paranaguá – PR;
                        </li>
                        <li>DOCK BRASIL – São Gonçalo – RJ;</li>
                        <li>DEC ENGENHARIA – Baia da Guanabara – RJ.</li><br />
                        <h4>Sondagem e investigação geotécnica em terra:</h4>
                        <li>CONSTRUTORA TENDA – Vários Empreendimentos;</li>
                        <li>DIRECIONAL – Vários Empreendimentos;</li>
                        <li>ARCELLOR MITAL – Xerém – RJ;</li>
                        <li>VIASUL ENGENHARIA – Vários Empreendimentos;</li>
                        <li>CONCER – Via 040 - RJ;</li>
                        <li>CONSEL ENGENHARIA – Três Rios – RJ;</li>
                        <li>POMPEI CONSTRUTORA – Muriaé – MG;</li>
                        <li>ENGELPAR PARTICIPAÇÕES – Manhuaçu – MG;</li>
                        <li>ACR EMPREENDIMENTOS – Juiz de Fora – MG;</li>
                        <li>SÃO JUDAS FUNDAÇÕES – Juiz de Fora e região – MG;</li>
                        <li>CARVALHO HOSKEN S.A ENGENHARIA E CONSTRUÇÕES – Vários Empreendimentos;</li>
                        <li>RJZ CYRELLA – Vários Empreendimentos;</li>
                        <li>INTER CONSTRUTORA – Juiz de Fora – MG;</li>
                        <li>SONDOTÉCNICA – Via 040 - MG;</li>
                        <li>MOINHO VERA CRUZ – Juiz de Fora – MG.</li><br />
                        <h4>Perfuração e instalação de dreno horizontal profundo:</h4>
                        <li>PANGEA EMPREENDIMENTOS – Mathias Barbosa – MG;</li>
                        <li>PAULO ROGERIO MONTES – Cataguases – MG.</li>
                        <h4>Projetos e obras de contenção de encostas:</h4>
                        <li>MWN INCORPORAÇÕES – Juiz de Fora – MG;</li>
                        <li>OLIVEIRA E SALLES – Juiz de Fora – MG;</li>
                        <li>CONSTRUTORA SILVA E SOUZA – Vários locais – RJ;</li>
                        <li>POÇO RICO INCORPORADORA – Juiz de Fora – MG.</li>
                        <h4>Perfuração e injeção de estaca raiz em solo e rocha:</h4>
                        <li>COLUMBIA CONSTRUTORA E EMPREENDIMENTOS – Juiz de Fora – MG;</li>
                        <li>POÇO RICO INCORPORADORA – Juiz de Fora – MG;</li>
                        <li>CONSTRUTORA OLIVEIRA E GONTIJO – Juiz de Fora – MG;</li>
                        <li>SÃO JUDAS FUNDAÇÕES – Juiz de Fora – MG;</li>
                        <li>GWR ENGENHARIA – Rio de Janeiro – RJ;</li>
                        <li>UP ENGENHARIA E URBANISMO – Juiz de Fora – MG;</li>
                        <li>MWN INCORPORAÇÕES – Juiz de Fora – MG.</li>
                        <h4>Perfuração em solo para instalação de anodos:</h4>
                        <li>IEC – INSTALAÇÕES E ENGENHARIA DE CORROSÃO – Rio de Janeiro.</li>
                        <h4>Amostragem em água com o Vibracore:</h4>
                        <li>BELOV ENGENHARIA – Porto de Paranaguá – PR;</li>
                        <li>SHAW MEIO AMBIENTE E INFRAESTRUTURA – Florianópolis – SC.</li>
                        <h4>Outros trabalhos:</h4>
                        <li>Mineroduto Minas/Rio – MMX</li>
                        <li>Mineroduto Minas/Espírito Santo – FERROUS RESOURCES</li>
                        <li>Terminal Marítimo Presidente Kennedy – FERROUS RENOIR</li>
                        <li>Terminal de Barcaças – ARCELORMITTAL</li>
                        <li>Ampliação Porto do Rio de Janeiro – DOCAS</li>
                        <li>Ampliação do Porto de Vitória – CARIOCA</li>
                        <li>Terminal Portuário Imetame – IMETAME</li>
                        <li>Terminal Portuário Capixaba – NUTRIPETRO</li>
                        <li>Terminal Portuário do Pecém – RAM / PLANAVE /MARQUISE</li>
                        <li>Porto do Açu – LLX / ANGLO AMERICAN</li>
                        <li>Terminal Marítimo Ponta da Madeira – Pier IV – VALE</li>
                        <li>Terminal Ferroviário Ponta da Madeira – VALE</li>
                        <li>Base Naval de Itaguaí – CNO</li>
                        <li>Terminal de Minério – Ponta de Tulha – BAMIN</li>
                        <li>Contenção de Encostas – PNSO – IBAMA</li>
                        <li>Manutenção de Sites de Telecomunicações – OI</li>
                        <li>Estaleiro Jurong Aracruz – JURONG</li>
                        <li>Túnel Vitória – Vila Velha – SONDOTÉCNICA</li>
                        <li>Terminal Itaoca off shore – ITAOCA</li>
                    </Col>
                </Row>
            </Container>
        </main>
    );
}

export default MainObras;
