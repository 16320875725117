import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Copyright from "../Copyright";
import "./styles.css";

function Footer() {
    return (
        <>
            <div className="footer">
                <Container>
                    <Row className="footer-infos">
                        <Col></Col>
                        <Col>
                            <h5>FALE CONOSCO</h5>
                            <p>
                                <strong>Telefones:</strong> +55 (21) 2427-6616 |
                                +55 (21) 2445-3170
                            </p>
                            <p>
                                <strong>E-mail:</strong> <strong><a href="/contatos">Envie uma mensagem</a>.</strong>
                            </p>
                        </Col>
                        <Col>
                            <h5>ENDEREÇO</h5>
                            <p>Estrada do Engenho D'água, 1360</p>
                            <p>Anil - Jacarepaguá - Rio de Janeiro</p>
                            <p>
                            + <strong><a href="/contatos">Veja no mapa</a>.</strong>
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <hr className="bottom fixclear" />
                    </Row>
                    <Copyright />
                </Container>
            </div>
        </>
    );
}

export default Footer;
