import React from 'react'
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import MainObras from "../../components/MainObras";
import Footer from "../../components/Footer";

function Obras() {
  return (
    <>
      <Helmet>
        <title>Geodrill | Obras</title>
      </Helmet>
      <Header />
      <MainObras />
      <Footer />
    </>
  );
}

export default Obras;
