/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import "./styles.css";

import logo from "../../assets/logo.png";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";

function Header() {

    return (
        <Navbar
            fixed="top"
            className="header-menu"
            bg="light"
            variant="light"
            expand="lg"
        >
            <Container>
                <Navbar.Brand href="/">
                    <img src={logo} alt="" className="logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav w-100" />
                <Navbar.Collapse id="basic-navbar-nav w-100">
                    <Nav className="nav-links me-auto">
                        <Nav.Link href="/">HOME</Nav.Link>
                        <Nav.Link href="/sobre">SOBRE</Nav.Link>
                        <NavDropdown
                            renderMenuOnMount={true}
                            autoClose="outside"
                            title="SERVIÇOS"
                            id="basic-nav-dropdown"
                        >
                            <NavDropdown.Item href="/servicos/aluguel-de-plataformas-autoelevatorias">
                                <a>ALUGUEL DE PLATAFORMAS</a>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/servicos/contencao-de-encostas">
                                <a>CONTENÇÃO DE ENCOSTAS</a>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/servicos/fundacoes-especiais-estaca-raiz">
                                <a>FUNDAÇÕES ESPECIAIS</a>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/servicos/obras-civis">
                                <a>OBRAS CIVIS</a>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/servicos/recuperacao-estrutural">
                                <a>RECUPERAÇÃO ESTRUTURAL</a>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/servicos/sondagens-subaquaticas-com-campanula-de-mergulho">
                                <a>SONDAGENS COM CAMPÂNULAS</a>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/servicos/sondagens-subaquaticas-com-flutuantes">
                                <a>SONDAGENS COM FLUTUANTE</a>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/servicos/sondagens-subaquaticas-com-plataformas">
                                <a>SONDAGENS COM PLATAFORMA</a>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/servicos/sondagens-subaquaticas-com-vibracore">
                                <a>SONDAGENS COM VIBRACORE</a>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/servicos/sondagens-geotecnicas">
                                <a>SONDAGENS GEOTÊCNICAS</a>
                            </NavDropdown.Item>


                        </NavDropdown>
                        <Nav.Link href="/obras">OBRAS</Nav.Link>
                        <Nav.Link href="/clientes">CLIENTES</Nav.Link>
                        <Nav.Link href="/contatos">CONTATOS</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
