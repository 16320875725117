import React from "react";
import { Col, Row } from "react-bootstrap";
import "./styles.css";

function Footer() {
    return (
        <>
            <Row>
                <Col className="copyrigth">
                    <p>
                        &copy; 2021 Smithson do Brasil - Todos os Direitos
                        Reservados.
                    </p>
                </Col>
            </Row>
        </>
    );
}

export default Footer;
