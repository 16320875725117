import React from "react";
import { Helmet } from "react-helmet";

import "./styles.css";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import sondagemFlutuante from "../../assets/imagem-sondagem-flutuante.jpg";

import { Container, Row, Col } from "react-bootstrap";

function SondagemFlutuante() {
    return (
        <>
            <Helmet>
                <title>Geodrill | Sondagens Subaquáticas com Flutuantes</title>
            </Helmet>
            <Header />
            <main className="sondagemFlutuante">
                <Container>
                    <Row>
                        <Col>
                            <h1>Sondagens Subaquáticas com Flutuantes</h1>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <p>
                            A Geodrill executa sondagens em rios, lagoas ou em
                            mar abrigado até lâmina d’água de 30,00m ou mais a
                            depender da correnteza local utilizando flutuantes
                            ou balsas. Este tipo de equipamento não é adequado a
                            locais com ondulações significativas.
                        </p>

                        <p>
                            Com os flutuantes ou balsas são executados todos os
                            tipos de sondagem e ensaios listados a seguir e que
                            a boa técnica não exija que as execute em base fixa
                            como os ensaios CPTU.
                        </p>

                        <strong>
                            <p>
                                Assim com os flutuantes são executadas as
                                seguintes investigações geotécnicas:
                            </p>
                        </strong>
                        <ul>
                            <li>Sondagem percussão com ensaio SPT;</li>
                            <li>Sondagem mista e rotativa;</li>
                            <li>Ensaios de perda de água;</li>
                            <li>Ensaio de palheta ( vane-test);</li>
                            <li>
                                Coleta de amostras indeformadas com shelby ou
                                denisson.
                            </li>
                        </ul>
                        <p>
                            As dimensões dos flutuantes ou balsas em geral são
                            definidos em função do porte dos equipamentos que
                            serão utilizados, da lâmina d`água e da correnteza
                            no local dos furos.
                        </p>
                    </Row>
                    <Row>
                        <Col>
                            <img src={sondagemFlutuante} alt="" />
                        </Col>
                    </Row>
                </Container>
            </main>

            <Footer />
        </>
    );
}

export default SondagemFlutuante;
