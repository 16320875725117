import React from 'react';
import Routes from './routes';
import './styles.css'
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Routes />
  );
}

export default App;
