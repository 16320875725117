import React from "react";
import { Helmet } from "react-helmet";

import "./styles.css";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Container, Row, Col } from "react-bootstrap";

import plataformasElevatorias2 from "../../assets/imagem-plataformas-elevatorias-2.jpg";
import plataformasElevatorias3 from "../../assets/imagem-plataformas-elevatorias-3.jpg";
import plataformasElevatorias4 from "../../assets/imagem-plataformas-elevatorias-4.jpg";
import plataformasElevatorias5 from "../../assets/imagem-plataformas-elevatorias-5.jpg";

function PlataformasElevatorias() {
    return (
        <>
            <Helmet>
                <title>Geodrill | Aluguel de Plataformas Autoelevatórias</title>
            </Helmet>
            <Header />
            <main className="plataformasElevatorias">
                <Container>
                    <Row>
                        <Col>
                            <h1>Aluguel de Plataformas Elevatórias</h1>
                            <hr />
                            <p>
                                As plataformas autoelevatórias (mini jack-up)
                                são utilizadas para sondagens e outros serviços
                                de apoio marítimo.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <img
                                src={plataformasElevatorias3}
                                alt=""
                                className="img-extra"
                            />
                        </Col>
                        <Col>
                            <img
                                src={plataformasElevatorias2}
                                alt=""
                                className="img-extra img-extra2"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                Oferecemos diversos modelos, com área de convés
                                variável de 36m² a 86m² e operar em lâmina
                                d’água de 1m a 25m.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <img
                            src={plataformasElevatorias5}
                            alt=""
                            className="img-extra"
                        />
                        </Col>

                        <Col>
                            <img
                                src={plataformasElevatorias4}
                                alt=""
                                className="img-extra"
                            />
                        </Col>
                    </Row>
                </Container>
            </main>
            <Footer />
        </>
    );
}

export default PlataformasElevatorias;
