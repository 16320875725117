import React from 'react'
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import MainContatos from '../../components/MainContatos';

function Contatos() {
  return (
    <>
      <Helmet>
        <title>Geodrill | Contatos</title>
      </Helmet>
      <Header />
      <MainContatos />
      <Footer />
    </>
  );
}

export default Contatos;