import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";

import "./styles.css";

import AluguelDePlataformas from "../../assets/aluguel-de-plataformas-home.jpg";
import ContencaoDeEncostas from "../../assets/contencao-home.jpg";
import FundacoesEspeciais from "../../assets/fundacoes-especiais-home.jpg";
import ObrasCivis from "../../assets/obras-civis-home.jpg";
import RecuperacaoEstrutural from "../../assets/recuperacao-estrutural-home.jpg";
import SondagemCampanula from "../../assets/sondagem-campanula-home.jpg";
import SondagemPlataforma from "../../assets/sondagem-plataforma-home.jpg";
import SondagemFlutuante from "../../assets/sondagem-flutuante-home.jpg";
import SondagemVibracore from "../../assets/sondagem-vibracore-home.jpg";
import SondagemGeotecnica from "../../assets/sondagem-geotecnica-home.jpg";

function CardsHome() {
    return (
        <main className="cards">
            <Container>
                <Row>
                    <Col>
                        <h1>Tipos de Serviço</h1>
                    </Col>
                </Row>
                <Row>
                    <div className="d-flex justify-content-around flex-wrap">
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Img
                                        variant="top"
                                        src={AluguelDePlataformas}
                                    />
                                    <Card.Body>
                                        <Card.Title>
                                            Aluguel de Plataformas
                                        </Card.Title>
                                        <Button
                                            variant="dark"
                                            href="/servicos/aluguel-de-plataformas-autoelevatorias"
                                        >
                                            Clique para saber mais!
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Img
                                        variant="top"
                                        src={ContencaoDeEncostas}
                                    />
                                    <Card.Body>
                                        <Card.Title>
                                            Contenção de Encostas
                                        </Card.Title>
                                        <Button
                                            variant="dark"
                                            href="/servicos/contencao-de-encostas"
                                        >
                                            Clique para saber mais!
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Img
                                        variant="top"
                                        src={FundacoesEspeciais}
                                    />
                                    <Card.Body>
                                        <Card.Title>
                                            Fundações Especiais
                                        </Card.Title>
                                        <Button
                                            variant="dark"
                                            href="/servicos/fundacoes-especiais-estaca-raiz"
                                        >
                                            Clique para saber mais!
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Img variant="top" src={ObrasCivis} />
                                    <Card.Body>
                                        <Card.Title>Obras Civis</Card.Title>
                                        <Button
                                            variant="dark"
                                            href="/servicos/obras-civis"
                                        >
                                            Clique para saber mais!
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Img
                                        variant="top"
                                        src={RecuperacaoEstrutural}
                                    />
                                    <Card.Body>
                                        <Card.Title>
                                            Recuperação Estrutural
                                        </Card.Title>
                                        <Button
                                            variant="dark"
                                            href="/servicos/recuperacao-estrutural"
                                        >
                                            Clique para saber mais!
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Img
                                        variant="top"
                                        src={SondagemCampanula}
                                    />
                                    <Card.Body>
                                        <Card.Title>
                                            Sondagens com Campânula
                                        </Card.Title>
                                        <Button
                                            variant="dark"
                                            href="/servicos/sondagens-subaquaticas-com-campanula-de-mergulho"
                                        >
                                            Clique para saber mais!
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Img
                                        variant="top"
                                        src={SondagemFlutuante}
                                    />
                                    <Card.Body>
                                        <Card.Title>
                                            Sondagens com Flutuante
                                        </Card.Title>
                                        <Button
                                            variant="dark"
                                            href="/servicos/sondagens-subaquaticas-com-flutuantes"
                                        >
                                            Clique para saber mais!
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Img
                                        variant="top"
                                        src={SondagemPlataforma}
                                    />
                                    <Card.Body>
                                        <Card.Title>
                                            Sondagens com Plataforma
                                        </Card.Title>
                                        <Button
                                            variant="dark"
                                            href="/servicos/sondagens-subaquaticas-com-plataformas"
                                        >
                                            Clique para saber mais!
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <Card.Img
                                        variant="top"
                                        src={SondagemVibracore}
                                    />
                                    <Card.Body>
                                        <Card.Title>
                                            Sondagens com Vibracore
                                        </Card.Title>
                                        <Button
                                            variant="dark"
                                            href="/servicos/sondagens-subaquaticas-com-vibracore"
                                        >
                                            Clique para saber mais!
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Img
                                        variant="top"
                                        src={SondagemGeotecnica}
                                    />
                                    <Card.Body>
                                        <Card.Title>
                                            Sondagens Geotêcnicas
                                        </Card.Title>
                                        <Button
                                            variant="dark"
                                            href="/servicos/sondagens-geotecnicas"
                                        >
                                            Clique para saber mais!
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Row>
            </Container>
        </main>
    );
}

export default CardsHome;
