import React from "react";
import { Helmet } from "react-helmet";

import "./styles.css";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Container, Row, Col, Figure } from "react-bootstrap";

import recuperacaoEstrutural from "../../assets/imagem-recuperacao-estrutural.jpg";
import recuperacaoEstrutural2 from "../../assets/imagem-recuperacao-estrutural-2.jpg";
import recuperacaoEstrutural3 from "../../assets/imagem-recuperacao-estrutural-3.jpg";
import recuperacaoEstrutural4 from "../../assets/imagem-recuperacao-estrutural-4.jpg";
import recuperacaoEstrutural5 from "../../assets/imagem-recuperacao-estrutural-5.jpg";
import recuperacaoEstrutural6 from "../../assets/imagem-recuperacao-estrutural-6.jpg";
import recuperacaoEstrutural7 from "../../assets/imagem-recuperacao-estrutural-7.jpg";
import recuperacaoEstrutural8 from "../../assets/imagem-recuperacao-estrutural-8.jpg";
import recuperacaoEstrutural9 from "../../assets/imagem-recuperacao-estrutural-9.jpg";
import recuperacaoEstrutural10 from "../../assets/imagem-recuperacao-estrutural-10.jpg";
import recuperacaoEstrutural11 from "../../assets/imagem-recuperacao-estrutural-11.jpg";
import recuperacaoEstrutural12 from "../../assets/imagem-recuperacao-estrutural-12.jpg";
import recuperacaoEstrutural13 from "../../assets/imagem-recuperacao-estrutural-13.jpg";
import recuperacaoEstrutural14 from "../../assets/imagem-recuperacao-estrutural-14.jpg";
import recuperacaoEstrutural15 from "../../assets/imagem-recuperacao-estrutural-15.jpg";

function RecuperacaoEstrutural() {
    return (
        <>
            <Helmet>
                <title>Geodrill | Recuperação Estrutural</title>
            </Helmet>
            <Header />
            <main className="recuperacaoEstrutural">
                <Container>
                    <Row>
                        <Col>
                            <h1>
                                Recuperação Estrutural e Reforço de Estruturas
                                de Concreto Armado
                            </h1>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                A recuperação das estruturas e seu reforço
                                quando necessário tem sido exigências das mais
                                importantes nos autos de vistorias de prédios e
                                condomínios bem como é situação freqüente na
                                manutenção das construções mais antigas em
                                especial aquelas com garagens e subsolos e
                                localizadas em ambientes marinhos. A Geodrill
                                tem colocado sua experiência ao longo dos anos
                                na correção das patologias encontradas sendo as
                                mais comuns o desplacamento , fissuras e trincas
                                da cobertura de concreto e a corrosão das
                                armaduras . Em geral estas patologias são
                                decorrentes de deficiências construtivas ou de
                                projeto, a composição dos agregados do concreto,
                                a pequena cobertura de concreto principalmente
                                nas construções mais antigas quando a Norma
                                Brasileira na ocasião permitia tais espessuras e
                                a ação de agentes agressivos externos sendo
                                estes os cloretos principalmente em ambientes
                                marinhos , CO2 - gás carbônico e umidade que ao
                                penetrarem nos poros do concreto provocam o
                                processo físico- químico da carbonatação que por
                                sua vez provoca a despassivação do ambiente
                                concreto - armadura e a conseqüente corrosão das
                                armaduras que dependendo da agressividade e
                                perda da seção do aço pode nos levar além do
                                reparo a necessidade de reforçar a estrutura.
                                Assim como de forma sucinta abordamos as causas
                                das patologias a seguir descrevemos os
                                procedimentos de correção estrutural.
                                Recuperação estrutural: A depender da patologia
                                e da análise criteriosa das causas os reparos
                                mais comuns consistem na remoção total do
                                concreto deteriorado pelo menos até o entorno
                                das armaduras com corrosão , o tratamento das
                                armaduras com acréscimo de novas barras se
                                necessário e também se necessário a injeção
                                prévia de fissuras ou trincas com resinas
                                especiais e se o ambiente é de contaminação
                                intensa a proteção catódica com anodos
                                sacrifício . Ao final o concreto removido é
                                recomposto com argamassas poliméricas ou
                                microconcreto ou groute cuidando para se obter
                                as espessuras de cobrimento das armaduras
                                recomendadas por Norma. Reforço estrutural
                                Quando é necessário o reforço estrutural seja
                                por cargas excessivas ,deficiência de projeto ou
                                construtiva , danos irreversíveis da
                                deterioração do concreto e da armadura ou por
                                necessidade futura de acréscimo de cargas sobre
                                será efetuado projeto específico dos reforços
                                onde as soluções mais utilizadas são o
                                encamisamento com acréscimo de armaduras e seção
                                do concreto de vigas ou pilares , a utilização
                                de fibra carbono ou chapas de aço , a adição de
                                vigas de concreto ou metálicas ou ainda
                                protensões externas . Em seguida apresentamos
                                algumas fotos com exemplos das patologias mais
                                comuns , de exemplos de reforços e de obras
                                acabadas.
                            </p>
                            <p>
                                Em geral estas patologias são decorrentes de
                                deficiências construtivas ou de projeto, a
                                composição dos agregados do concreto, a pequena
                                cobertura de concreto principalmente nas
                                construções mais antigas quando a Norma
                                Brasileira na ocasião permitia tais espessuras e
                                a ação de agentes agressivos externos sendo
                                estes os cloretos principalmente em ambientes
                                marinhos , CO2 - gás carbônico e umidade que ao
                                penetrarem nos poros do concreto provocam o
                                processo físico- químico da carbonatação que por
                                sua vez provoca a despassivação do ambiente
                                concreto - armadura e a conseqüente corrosão das
                                armaduras que dependendo da agressividade e
                                perda da seção do aço pode nos levar além do
                                reparo a necessidade de reforçar a estrutura.
                            </p>
                            <p>
                                Assim como de forma sucinta abordamos as causas
                                das patologias a seguir descrevemos os
                                procedimentos de correção estrutural.
                            </p>
                            <h5>Recuperação estrutural:</h5>
                            <p>
                                A depender da patologia e da análise criteriosa
                                das causas os reparos mais comuns consistem na
                                remoção total do concreto deteriorado pelo menos
                                até o entorno das armaduras com corrosão , o
                                tratamento das armaduras com acréscimo de novas
                                barras se necessário e também se necessário a
                                injeção prévia de fissuras ou trincas com
                                resinas especiais e se o ambiente é de
                                contaminação intensa a proteção catódica com
                                anodos sacrifício . Ao final o concreto removido
                                é recomposto com argamassas poliméricas ou
                                microconcreto ou groute cuidando para se obter
                                as espessuras de cobrimento das armaduras
                                recomendadas por Norma.
                            </p>
                            <h5>Reforço estrutural:</h5>
                            <p>
                                Quando é necessário o reforço estrutural seja
                                por cargas excessivas ,deficiência de projeto ou
                                construtiva , danos irreversíveis da
                                deterioração do concreto e da armadura ou por
                                necessidade futura de acréscimo de cargas sobre
                                será efetuado projeto específico dos reforços
                                onde as soluções mais utilizadas são o
                                encamisamento com acréscimo de armaduras e seção
                                do concreto de vigas ou pilares , a utilização
                                de fibra carbono ou chapas de aço , a adição de
                                vigas de concreto ou metálicas ou ainda
                                protensões externas.
                            </p>
                            <p>
                                Em seguida apresentamos algumas fotos com
                                exemplos das patologias mais comuns , de
                                exemplos de reforços e de obras acabadas.
                            </p>
                        </Col>
                        <Row>
                            <Col>
                                <Figure>
                                    <Figure.Image
                                        alt=""
                                        src={recuperacaoEstrutural}
                                    />
                                    <Figure.Caption>
                                        Reforço com fibra de carbono
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                            <Col>
                                <Figure>
                                    <Figure.Image
                                        alt=""
                                        src={recuperacaoEstrutural2}
                                    />
                                    <Figure.Caption>
                                        Reforço com fibra de carbono
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Figure>
                                    <Figure.Image
                                        alt="Exemplos de Recuperação Estrutural"
                                        src={recuperacaoEstrutural3}
                                    />
                                    <Figure.Caption>
                                        Tratamento da armadura com reposição de
                                        elementos de aço, proteção catódica e
                                        concreto Grout
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                            <Col>
                                <Figure>
                                    <Figure.Image
                                        alt="Exemplos de Recuperação Estrutural"
                                        src={recuperacaoEstrutural4}
                                    />
                                    <Figure.Caption>
                                        Exemplos de Recuperação Estrutural
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Figure>
                                    <Figure.Image
                                        alt=""
                                        src={recuperacaoEstrutural5}
                                    />
                                    <Figure.Caption>
                                        Exemplos de estruturas pós Recuperação
                                        Estrutural
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                            <Col>
                                <Figure>
                                    <Figure.Image
                                        alt="Pilar - Concreto deteriorado e armadura com corrosão, porém, sem perda de seção."
                                        src={recuperacaoEstrutural6}
                                    />
                                    <Figure.Caption>
                                        Pilar - Concreto deteriorado e armadura
                                        com corrosão, porém, sem perda de seção
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Figure>
                                    <Figure.Image
                                        alt="Viga - Concreto desagrado, com baixa qualidade e pouca cobertura."
                                        src={recuperacaoEstrutural7}
                                    />
                                    <Figure.Caption>
                                        Viga - Concreto desagrado, com baixa
                                        qualidade e pouca cobertura
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                            <Col>
                                <Figure>
                                    <Figure.Image
                                        alt="Viga - Concreto desagrado, com baixa qualidade e pouca cobertura."
                                        src={recuperacaoEstrutural8}
                                    />
                                    <Figure.Caption>
                                        Laje com concreto deteriorado e armadura
                                        com corrosão
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Figure>
                                    <Figure.Image
                                        alt="Viga - Concreto desagrado, com baixa qualidade e pouca cobertura."
                                        src={recuperacaoEstrutural9}
                                    />
                                    <Figure.Caption>
                                        Pilar com desplatamento fissuras e
                                        corrosão
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                            <Col>
                                <Figure>
                                    <Figure.Image
                                        alt="Viga - Concreto desagrado, com baixa qualidade e pouca cobertura."
                                        src={recuperacaoEstrutural10}
                                    />
                                    <Figure.Caption>Pilar</Figure.Caption>
                                </Figure>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Figure>
                                    <Figure.Image
                                        alt="Viga - Concreto desagrado, com baixa qualidade e pouca cobertura."
                                        src={recuperacaoEstrutural11}
                                    />
                                    <Figure.Caption>Pilar</Figure.Caption>
                                </Figure>
                            </Col>
                            <Col>
                                <Figure>
                                    <Figure.Image
                                        alt="Viga - Concreto desagrado, com baixa qualidade e pouca cobertura."
                                        src={recuperacaoEstrutural12}
                                    />
                                    <Figure.Caption>
                                        Pilar com concreto deteriorado, armadura
                                        com corrosão e perda de seção
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Figure>
                                    <Figure.Image
                                        alt="Viga - Concreto desagrado, com baixa qualidade e pouca cobertura."
                                        src={recuperacaoEstrutural13}
                                    />
                                    <Figure.Caption>
                                        Pilar com concreto deteriorado, armadura
                                        com corrosão e perda de seção
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                            <Col>
                                <Figure>
                                    <Figure.Image
                                        alt="Viga - Concreto desagrado, com baixa qualidade e pouca cobertura."
                                        src={recuperacaoEstrutural14}
                                    />
                                    <Figure.Caption>
                                        Pilar e viga
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Figure>
                                    <Figure.Image
                                        alt="Viga - Concreto desagrado, com baixa qualidade e pouca cobertura."
                                        src={recuperacaoEstrutural15}
                                    />
                                    <Figure.Caption>Pilar</Figure.Caption>
                                </Figure>
                            </Col>
                        </Row>
                    </Row>
                </Container>
            </main>
            <Footer />
        </>
    );
}

export default RecuperacaoEstrutural;
