/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import imagem1 from "../../assets/imagem-1.jpg";
import imagem2 from "../../assets/imagem-2.jpg";
import imagem3 from "../../assets/imagem-3.jpg";
import imagem4 from "../../assets/imagem-4.jpg";
import imagem5 from "../../assets/imagem-5.jpg";
import imagem6 from "../../assets/imagem-6.jpg";
import imagem7 from "../../assets/imagem-7.jpg";
import imagem8 from "../../assets/imagem-8.jpg";
import imagem9 from "../../assets/imagem-9.jpg";
import imagem10 from "../../assets/imagem-10.jpg";
import imagem11 from "../../assets/imagem-11.jpg";
import imagem12 from "../../assets/imagem-12.jpg";
import imagem13 from "../../assets/imagem-13.jpg";
import imagem14 from "../../assets/imagem-14.jpg";

import "./styles.css";
import { Col, Container, Row } from "react-bootstrap";

function MainSobre() {
    return (
        <main className="sobre">
            <Container>
                <Container>
                    <Row>
                        <Col>
                            <h1>Sobre</h1>
                            <hr />
                            <p>
                                A Geodrill é uma empresa especializada em
                                serviços de engenharia com foco em{" "}
                                <strong>
                                    Sondagens Geotécnicas Subaquáticas e
                                    Terrestres, Contenção de Encostas,
                                    Recuperação Estrutural e Obras Civis
                                </strong>
                                . Desde 1998 no mercado, a empresa executa obras
                                em todo território brasileiro, atuando em
                                importantes projetos de{" "}
                                <strong>
                                    <a href="/clientes">grandes clientes</a>.
                                </strong>
                            </p>
                            <p>
                                Com soluções diferenciadas e inovadoras, a
                                Geodrill Engenharia pontua sua história a partir
                                da busca criativa pela qualidade total
                                permanente, fortalecendo a parceria e a lealdade
                                no desenvolvimento de projetos junto a clientes
                                e fornecedores.
                            </p>
                            <p>
                                A conquista de grandes desafios e a confiança de
                                grandes clientes se devem à qualidade dos
                                profissionais que constituem seu corpo técnico.
                                São especialistas com mais de 30 anos de
                                experiência no setor e trabalhos bem sucedidos
                                em grandes empresas do país.
                            </p>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row>
                        <Col className="step intro">
                            <h4>Por que contratar a Geodrill?</h4>
                            <p>
                                Acreditamos que temos 3 principais
                                características que nos capacitam a enfrentar
                                toda gama de desafios no mercado de sondagens
                                geotécnicas.
                            </p>
                        </Col>
                        <Col className="step step1">
                            <h4>EXPERIÊNCIA</h4>
                            <p>
                                São mais de 20 anos de atuação no mercado
                                brasileiro com um corpo técnico de profissionais
                                sêniors acostumados a enfrentar desafios em
                                diversos tipos de obras e projetos.
                            </p>
                        </Col>
                        <Col className="step step2">
                            <h4>AGILIDADE</h4>
                            <p>
                                Empresa sem burocracia, compacta, mas que pensa
                                e age GRANDE. Contamos com uma rede qualificada
                                de parceiros, unindo soluções práticas para
                                entregar em prazos mais curtos.
                            </p>
                        </Col>
                        <Col className="step step3">
                            <h4>TECNOLOGIA</h4>
                            <p>
                                A experiência nos permitiu desenvolver
                                equipamentos inovadores que oferecem resultados
                                mais seguros e com mais qualidade, atendendo à
                                crescente demanda do mercado.
                            </p>
                        </Col>
                    </Row>
                </Container>

                <Container className="sobre-imagens">
                    <Row>
                        <Col className="theHoverBorder">
                            <img src={imagem1} alt="" />
                        </Col>
                        <Col className="theHoverBorder">
                            <img src={imagem2} alt="" />
                        </Col>
                        <Col className="theHoverBorder">
                            <img src={imagem3} alt="" />
                        </Col>
                        <Col className="theHoverBorder">
                            <img src={imagem4} alt="" />
                        </Col>
                        <Col className="theHoverBorder">
                            <img src={imagem5} alt="" />
                        </Col>
                        <Col className="theHoverBorder">
                            <img src={imagem6} alt="" />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="theHoverBorder">
                            <img src={imagem7} alt="" />
                        </Col>
                        <Col className="theHoverBorder">
                            <img src={imagem8} alt="" />
                        </Col>
                        <Col className="theHoverBorder">
                            <img src={imagem9} alt="" />
                        </Col>
                        <Col className="theHoverBorder">
                            <img src={imagem10} alt="" />
                        </Col>
                        <Col className="theHoverBorder">
                            <img src={imagem11} alt="" />
                        </Col>
                        <Col className="theHoverBorder">
                            <img src={imagem12} alt="" />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="theHoverBorder">
                            <img src={imagem13} alt="" />
                        </Col>
                        <Col className="theHoverBorder">
                            <img src={imagem14} alt="" />
                        </Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                    </Row>
                </Container>

                <Container>
                    <Row>
                        <Col>
                            <h5>Diretores:</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="diretores">
                            <h6>Eduardo Lopes Lobato</h6>
                            <p>
                                Engenheiro Civil Geotécnico, formado pela
                                Universidade Federal Fluminense - UFF, em 1971.
                            </p>
                            <p>CREA RJ 1531D</p>
                        </Col>
                        <Col className="diretores">
                            <h6>Geraldo de Oliveira Almeida</h6>
                            <p>
                                Formado em 1986 pela Universidade Federal Rural
                                do Rio de Janeiro (UFFRJ), Geraldo é Geólogo
                                Geotécnico.
                            </p>
                            <p>CREA RJ 861053121-6</p>
                        </Col>
                        <Col className="diretores">
                            <h6>Lincoln José Coelho</h6>
                            <p>
                                Foi em 1984 que Lincoln Coelho se formou como
                                Geólogo Geotécnico na Universidade Federal do
                                Rio de Janeiro (UFRJ).
                            </p>
                            <p>CREA RJ 84107260-5</p>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row>
                        <h5>Onde Estamos:</h5>
                    </Row>
                    <Row>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3673.84727275699!2d-43.35048044893314!3d-22.95585114532236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9bd9c782f6cb43%3A0xe7654fb5bd96b7ea!2sEstr.%20do%20Engenho%20d&#39;%C3%81gua%2C%201360%20-%20Anil%2C%20Rio%20de%20Janeiro%20-%20RJ%2C%2022765-240!5e0!3m2!1spt-BR!2sbr!4v1638189155518!5m2!1spt-BR!2sbr"
                            width="600"
                            height="450"
                        ></iframe>
                    </Row>
                </Container>
            </Container>
        </main>
    );
}

export default MainSobre;
