import React from "react";
import { Helmet } from "react-helmet";

import "./styles.css";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Container, Row, Col } from "react-bootstrap";

import sondagemComCampanula2 from "../../assets/imagem-sondagem-com-campanula-2.jpg";
import sondagemComCampanula3 from "../../assets/imagem-sondagem-com-campanula-3.jpg";
import sondagemComCampanula4 from "../../assets/imagem-sondagem-com-campanula-4.jpg";
import sondagemComCampanula5 from "../../assets/imagem-sondagem-com-campanula-5.jpg";
import sondagemComCampanula6 from "../../assets/imagem-sondagem-com-campanula-6.jpg";
import Image from 'react-bootstrap/Image'

function SondagemCampanula() {
    return (
        <>
            <Helmet>
                <title>
                    Geodrill | Sondagens Subaquáticas com Campânula de Mergulho
                </title>
            </Helmet>
            <Header />
            <main className="sondagemCampanula">
                <Container>
                    <Row>
                        <Col>
                            <h1>
                                Sondagens Subaquáticas com Campânula de Mergulho
                            </h1>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                <Image src={sondagemComCampanula2} fluid alt=""/>
                                As campânulas, também chamadas de sino de
                                mergulho, são câmaras pneumáticas munidas de
                                equipamento de sondagem, capazes de submergir na
                                água para realizar ensaios de solo. Trata-se de
                                um solução conhecida há mais de 40 anos no meio
                                geotécnico brasileiro.
                            </p>
                            <p>
                                Em 2006, a Geodrill adequou as dimensões destas
                                campânulas para execução de investigações
                                geotécnicas como se a equipe estivesse em terra
                                e criou, desenvolveu e patenteou um sistema de
                                transporte e fundeio, sem utilização de grandes
                                embarcações e guindastes. Este sistema, aliado à
                                qualidade técnica das investigações, vem obtendo
                                grande sucesso no Brasil, sendo aplicado em
                                estudos de portos, terminais portuários, pontes
                                de acesso, dragagem etc.
                            </p>
                            <p>
                                As campânulas Geodrill são inteiramente
                                desmontadas, transportadas por meio rodoviário e
                                montadas e lançadas ao mar, no próprio local dos
                                serviços. A campânula montada tem 6m de altura e
                                opera no mar com lâmina d’água entre 8m e 30m,
                                podendo ser utilizada em lâminas d’água menores
                                e, em condições de mar favoráveis, em lâminas
                                d’água maiores, desde que com adequações
                                estruturais.
                            </p>
                            <p>
                                Em 2013, a Geodrill já executou, com pleno
                                sucesso, cerca de 700 pontos de investigação com
                                campânula ao longo da costa brasileira e 15.000
                                metros de sondagem para diferentes clientes.
                            </p>
                            <p>
                                As operações de sondagem subaquática executadas
                                à partir do interior da campânula só se iniciam
                                quando o sino encontra-se totalmente submerso,
                                apoiado e fundeado no leito marinho e o ambiente
                                no seu interior estiver totalmente seco. As
                                sondagens com campânula são executadas livres
                                das ondas e correntezas marinhas e são
                                monitoradas e comandadas, em tempo integral, por
                                operador especializado utilizando câmeras de
                                vídeo e fonia, a partir de uma embarcação
                                posicionada na superfície, próximo à campânula.
                            </p>
                            <p>
                                Os equipamentos de sondagem e ensaios
                                geotécnicos utilizados no interior da campânula
                                são os mesmos utilizados nas sondagens em terra
                                e seguem rigorosamente as Normas Brasileiras
                                afins.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Image src={sondagemComCampanula3} alt="" fluid className="img-extra"/>
                        </Col>
                        <Col>
                            <Image src={sondagemComCampanula4} alt="" fluid className="img-extra"/>
                        </Col>
                    </Row>

                    <Row>
                        <h4>Vantagens do sistema:</h4>

                        <p>
                            A campânula é uma alternativa ao uso de plataforma
                            auto-elevatória e pode apresentar vantagens técnicas
                            e financeiras quando ocorrerem as seguintes
                            situações:
                        </p>

                        <p>
                            <strong>a)</strong> Quando o calado e porte das plataformas
                            auto-elevatórias e das embarcações que lhe dão apoio
                            têm dificuldade de navegação e acesso ao local dos
                            serviços;
                        </p>

                        <p>
                        <strong>b)</strong> Quando o custo e o prazo de mobilização e
                            desmobilização da plataforma é superior ao custo e
                            prazo da campânula, que em geral é bem inferior.
                        </p>
                        <p>
                        <strong>c)</strong> Quando ocorre, no local dos furos, fundo marinho
                            com camadas de solos moles a muito moles, que
                            colocam em risco a estabilidade da plataforma;
                        </p>

                        <p>
                        <strong>d)</strong> Quando as sondagens forem executadas em períodos
                            sujeito a condições adversas de mar, principalmente
                            ondas altas e ventos fortes. Estas condições
                            provocam para as plataformas quantidade
                            significativa de dias parados com impacto nos custos
                            e cronograma bem superiores ao uso das campânulas.
                            Sob condições adversas de mar, as plataformas são
                            mais sujeitas à paralisação, devido ao grande risco
                            de acidentes graves com as equipes no transbordo
                            para embarque e desembarque da plataforma e, também,
                            por questões de segurança, há grandes riscos de
                            acidente com a plataforma nas operações de elevação
                            e descida das pernas, deslocamento e fundeio da
                            plataforma.
                        </p>

                        <p>
                        <strong>e)</strong> Quando a lâmina d’água mais profunda, maior que
                            15m a 20m, exige o uso de plataformas de grande
                            porte, assim como as embarcações que lhe dão apoio
                            (rebocadores, helicópteros etc), que têm custo bem
                            mais elevado que o das campânulas.
                        </p>
                    </Row>

                    <Row>
                        <Col>
                            <Image src={sondagemComCampanula5} alt="" fluid className="img-extra"/>
                        </Col>
                        <Col>
                            <Image src={sondagemComCampanula6} alt="" fluid className="img-extra"/>
                        </Col>
                    </Row>
                </Container>
            </main>

            <Footer />
        </>
    );
}

export default SondagemCampanula;
