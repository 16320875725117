/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import "./styles.css";

import Acciona from "../../assets/acciona.jpg";
import AG from "../../assets/ag.jpg";
import AngloAmerican from "../../assets/angloamerican.jpg";
import CSN from "../../assets/csn.jpg";
import EJA from "../../assets/eja.jpg";
import Engevix from "../../assets/engevix.jpg";
import Ferrous from "../../assets/ferrous.jpg";
import Gerdau from "../../assets/gerdau.jpg";
import Invepar from "../../assets/invepar.jpg";
import LLX from "../../assets/llx.jpg";
import Marquise from "../../assets/marquise.jpg";
import MMX from "../../assets/mmx.jpg";
import MPE from "../../assets/mpe.jpg";
import MPX from "../../assets/mpx.jpg";
import Oas from "../../assets/oas.jpg";
import Odebretch from "../../assets/odebrecht.jpg";
import Planave from "../../assets/planave.jpg";
import Transpetro from "../../assets/transpetro.jpg";
import Vale from "../../assets/vale.jpg";
import Petrobras from "../../assets/logo-petrobras.jpg";
import ICMbio from "../../assets/logo-icmbio.jpg";
import Claro from "../../assets/logo-claro.jpg";
import CORio2016 from "../../assets/logo-rio2016.jpg";

import { Col, Container, Row } from "react-bootstrap";

function MainClientes() {
    return (
        <main className="clientes">
            <Container>
                <Row>
                    <Col>
                        <h1>Clientes</h1>
                        <hr />
                        <p>
                            A Geodrill está acostumada a enfrentar desafios ao
                            lado de grandes clientes dos mais diversos cantos do
                            Brasil.
                        </p>
                        <p>
                            Além de atender a empresas e organizações de grande
                            porte como IBAMA, Construtora Santa Bárbara,
                            Carvalho Hosken, Arcelor Mittal, Prosint – GPC,
                            Embratel, MRS, OI, Votorantin, Bahia Mineração,
                            GeoRio, TV Globo, BHP Mineração, VogBR,
                            Sondotecnica, ARG, Nokia Siemens e Ericsson, a
                            Geodrill também teve a satisfação de trabalhar com:
                        </p>
                    </Col>
                </Row>
                <Row className="grid">
                    <Col className="theHoverBorder">
                        <a href="https://www.acciona.com.br">
                            <img src={Acciona} alt="" />
                        </a>
                    </Col>
                    <Col className="theHoverBorder">
                        <a href="https://www.andradegutierrez.com.br">
                            <img src={AG} alt="" />
                        </a>
                    </Col>
                    <Col className="theHoverBorder">
                        <a href="https://brasil.angloamerican.com/pt-pt">
                            <img src={AngloAmerican} alt="" />
                        </a>
                    </Col>
                    <Col className="theHoverBorder">
                        <a href="https://www.csn.com.br">
                            <img src={CSN} alt="" />
                        </a>
                    </Col>
                    <Col className="theHoverBorder">
                        <a href="#">
                            <img src={EJA} alt="" />
                        </a>
                    </Col>
                    <Col className="theHoverBorder">
                        <a href="#">
                            <img src={Engevix} alt="" />
                        </a>
                    </Col>
                </Row>
                <Row>
                    <Col className="theHoverBorder">
                        <a href="#">
                            <img src={Ferrous} alt="" />
                        </a>
                    </Col>
                    <Col className="theHoverBorder">
                        <a href="https://www2.gerdau.com.br">
                            <img src={Gerdau} alt="" />
                        </a>
                    </Col>
                    <Col className="theHoverBorder">
                        <a href="https://www.invepar.com.br">
                            <img src={Invepar} alt="" />
                        </a>
                    </Col>
                    <Col className="theHoverBorder">
                        <a href="#">
                            <img src={LLX} alt="" />
                        </a>
                    </Col>
                    <Col className="theHoverBorder">
                        <a href="https://grupomarquise.com.br">
                            <img src={Marquise} alt="" />
                        </a>
                    </Col>
                    <Col className="theHoverBorder">
                        <a href="http://www.mmx.com.br">
                            <img src={MMX} alt="" />
                        </a>
                    </Col>
                </Row>
                <Row>
                    <Col className="theHoverBorder">
                        <a href="#">
                            <img src={MPE} alt="" />
                        </a>
                    </Col>
                    <Col className="theHoverBorder">
                        <a href="#">
                            <img src={MPX} alt="" />
                        </a>
                    </Col>
                    <Col className="theHoverBorder">
                        <a href="#">
                            <img src={Oas} alt="" />
                        </a>
                    </Col>
                    <Col className="theHoverBorder">
                        <a href="https://www.oec-eng.com">
                            <img src={Odebretch} alt="" />
                        </a>
                    </Col>
                    <Col className="theHoverBorder">
                        <a href="https://www.planave.com">
                            <img src={Planave} alt="" />
                        </a>
                    </Col>
                    <Col className="theHoverBorder">
                        <a href="http://transpetro.com.br/transpetro-institucional/">
                            <img src={Transpetro} alt="" />
                        </a>
                    </Col>
                </Row>
                <Row>
                    <Col className="theHoverBorder">
                        <a href="http://www.vale.com/brasil/PT/Paginas/default.aspx">
                            <img src={Vale} alt="" />
                        </a>
                    </Col>
                    <Col className="theHoverBorder">
                        <a href="https://petrobras.com.br/pt/">
                            <img src={Petrobras} alt="" />
                        </a>
                    </Col>
                    <Col className="theHoverBorder">
                        <a href="https://www.icmbio.gov.br/portal/">
                            <img src={ICMbio} alt="" />
                        </a>
                    </Col>
                    <Col className="theHoverBorder">
                        <a href="https://www.claro.com.br">
                            <img src={Claro} alt="" />
                        </a>
                    </Col>
                    <Col className="theHoverBorder">
                        <a href="https://www.cob.org.br/pt/">
                            <img src={CORio2016} alt="" />
                        </a>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        </main>
    );
}

export default MainClientes;
