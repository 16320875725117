import React from "react";
import { Helmet } from "react-helmet";

import "./styles.css";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { Container, Row, Col } from "react-bootstrap";

import contencaoDeEncostas2 from "../../assets/imagem-contencao-de-encostas-2.jpg";
import AccordionContainerContencao from "../../components/AccordionContainerContencao";

function ContencaoDeEncostas() {
    return (
        <>
            <Helmet>
                <title>Geodrill | Contenção de Encostas</title>
            </Helmet>
            <Header />
            <main className="contencaoDeEncostas">
                <Container>
                    <Row>
                        <Col>
                            <h1>Contenção de Encostas</h1>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                <img src={contencaoDeEncostas2} alt="" />
                                Os principais tipos de obras de contenção e
                                estabilização de taludes e seus principais
                                componentes executadas pela Geodrill, são:
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <AccordionContainerContencao />
                        </Col>
                    </Row>
                </Container>
            </main>

            <Footer />
        </>
    );
}

export default ContencaoDeEncostas;
